/* SideNav.css */

.custom-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 76px;
  background-image: linear-gradient(-225deg, #77FFD2 0%, #6297DB 48%, #1EECFF 100%);
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s ease;
  z-index: 1000;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.custom-open {
  width: 160px;
}

.custom-toggle-button {
  background-color: rgba(255, 255, 255, 0.1);
  color: black;
  border: none;
  padding: 10px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease;
}

.custom-toggle-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.custom-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.custom-nav-logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.custom-nav-logo-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.custom-nav-list {
  width: 100%;
}

.custom-nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ecf0f1;
  margin: 10px 0;
  transition: background-color 0.3s ease;
  padding: 8px;
  border-radius: 5px;
}

.custom-nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.custom-nav-icon {
  font-size: 18px;
  margin-right: 10px;
  color: black;
}

.custom-nav-name {
  display: none;
  color: black;
}

.custom-open .custom-nav-name {
  display: inline;
}

/* Added styles for expanding on hover */
.custom-sidebar:hover {
  width: 139px;
}



.custom-nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #ecf0f1;
  margin: 10px 0;
  transition: background-color 0.3s ease;
  padding: 8px;
  border-radius: 5px;
}

.custom-nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.custom-nav-icon {
  font-size: 18px;
  margin-right: 10px;
  color: black;
}
.custom-sidebar:hover .custom-nav-name {
  display: inline;
}

.custom-nav-name {
  display: none;
  color: black;
}
