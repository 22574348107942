/* HomePage.css */
.navbar {
  display: flex;
  justify-content: space-between; /* Updated to space-between */
  align-items: center; /* Vertically center the content */
  width: auto;
  z-index: 1000;
  background: transparent;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 200px;
  height: 100px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  }

.nav-links li a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}


.cta-button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #61dafb;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.hero-section {
  width: 100%;
  height: 80vh;
  position: relative;
  background-image: radial-gradient(circle, rgb(163, 14, 233));

}

.big-quote {
  font-size: 2rem;
  font-weight: bold;
  color:#333;
  font-family: 'DM Sans';
}

.small-quote {
  font-size: 2rem;
  color: #363648;
}

.cta-button {

    background-image: linear-gradient(to right, #DA22FF 0%, #9733EE  51%, #DA22FF  100%);
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
 
  .bussiness{
    position: absolute;
    bottom: 65px;
  }




.body-area{
background-color: darkgrey;
}



.container-with-background {
  background: url('./Images/hero-banner-bg.png') no-repeat center;
  background-size: cover;
}

.text-left{
  margin-right: 780px;
}



/* Update HomePage.css */

.subscription-container {
  background-color: #61dafb;
  padding: 50px 0;
  text-align: center;
  margin-top: 19px;
}

.subscription-content {
  max-width: 600px;
  margin: 0 auto;
}

.subscription-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-container input {
  flex: 1;
  padding: 15px;
  margin-right: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 1rem;
  color: #495057;
}

.subscription-container button.subscribe-button {
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.subscription-container button.subscribe-button:hover {
  background-color: #0056b3;
}
