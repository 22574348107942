/* src/components/Whyapi.css */
.whyapi-section {
  display: flex;
  align-items: center;
  justify-content: center; /* Center horizontally */
  padding: 20px; /* Add padding to the entire section */
  margin-top: 80px;
}

.left-content {
  flex: 1;
  margin-top: 20px;
}

.right-content {
  flex: 1;
}

.api-image {
  box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
