/* Footer.css */
.bg-light  {
  background-color:#001f3f;
  }
  
  .footer-container {
    padding: 20px;
    margin-top: 36px;
    justify-content: space-around;
  }
  
  .footer-column {
    margin-bottom: 20px;
  }
  
  .footer-column h4 {
    color: #333;
  }
  
  .footer-column p {
    color: #666;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
  }
  
  .footer-links li {
    margin-bottom: 8px;
  }
  
  .footer-links a {
    color: #007bff;
    text-decoration: none;
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    margin-top: 20px;
    color: #555;
  }
  



.footer-images{
  width: 40%;
  
}







.social-icons {
  display: flex;
  gap: 14px;
}

.icon {
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #007bff;
}