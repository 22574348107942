 .body{
  overflow-x: hidden;
} 

.container {
    max-width: 600px; 
    margin: auto;
   
  }
 
  .row {
    justify-content: center;
  }
  
  .col-md-12 {
    margin-top: 20px;
  }
  
  form {
    margin-bottom: 20px;
  }
  .grid-ui{
    margin-top: 100px;
  }
  .btn-primary {
    margin-top: 10px;
  }
  
  /* Style for Accordion */
  .Accordion.Header {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    padding: 9px;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
  }
  
  .Accordion.Header:hover {
    background-color: #0056b3;
  }
  
  .Accordion.Body {
    padding: 15px;
    border: 1px solid #351313;
    border-top: none;
  }
  
  .my-button {
    padding: 8px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    width: 90px;
    margin-right: 20px;
  }
  
  .my-button-post {
    background-color: #28a745; /* Green color for POST button */
    color: #fff; /* White text color for POST button */
  }
  
  .my-button-get {
    background-color: #007bff; /* Blue color for GET button */
    color: #fff; /* White text color for GET button */
  }
  
.my-api-response{
  background-color: black;
  color: #5cb85c;
  border-radius: 2px;
  cursor: pointer;
}


.body-area {

  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
}

/* Add these styles to your Login.css file */

.my-accordion-item {
  border: 1px solid #dee2e6; /* Border color for the accordion items */
  border-radius: 0.25rem; /* Border radius for a slightly rounded appearance */
  margin-bottom: 10px; /* Adjust the bottom margin for spacing between accordion items */
}

.my-accordion-item .my-accordion-header {
  background-color: #007bff; /* Header background color for active accordion items */
  color: #fff; /* Text color for the header */
}

.my-accordion-item .my-accordion-body {
  padding: 1%; /* Adjust padding for the body content */
}

.my-button-post {
  background-color: #28a745; 
  color: #fff; 
}

.my-button-get {
  background-color: #007bff; 
  color: #fff; 
}
.my-accordion-item.active .my-accordion-body {
  padding: 15px;
} 

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  outline: none!important;
  width: 100%;
  padding: 6px!important;
  border-radius: 0px!important;
}
.accordion-button:focus {
  border: none!important;
  outline: none!important;
} 
.static-text {
  background-color: #f2f2f2;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.static-text h6 {
  color: #333;
  font-size: 18px;
  margin-bottom: 0;
}
