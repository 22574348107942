.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    z-index: 1000;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease; 
  }
  
  .transparent-background {
    background-color: transparent;
  }
  
  .logo-container {
    order: 1;
  }
  
  .logo {
    max-height: 76px;
    object-fit: contain;
    margin-left: 106px;
  }
  
  .nav-links {
    order: 2;
  }
  
  .nav-links a {
    margin-right: 80px;
   
  }
  
  .scrolled {
    background-color: rgba(255, 255, 255, 0.1); /* Adjust the background color and opacity for the glassmorphism effect */
    backdrop-filter: blur(10px); /* Add the backdrop-filter for the frosted glass effect */
  }
  